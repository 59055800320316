<script setup lang="ts">
import { BaseIcon } from '@legacy-studio/library';

import { useEducationStore } from '~/src/features/education/infrastructure/store';
import { useDrawer } from '~/src/widgets/terminal/useDrawer';
import { TraderDrawers } from '~/src/widgets/terminal/enum';

const store = useEducationStore();
const { selectedItems } = storeToRefs(store);
const { openDrawer } = useDrawer();
const handleSelect = (id: string) => {
	store.selectSecondLevelItem(id);
	openDrawer(TraderDrawers.EducationDetail);
};
</script>

<template>
	<div
		class="flex flex-col gap-6 pt-4"
	>
		<ul class="space-y-4">
			<li
				v-for="item in selectedItems"
				:key="item.id"
				class="text-gray-400 hover:text-white leading-tight text-sm font-normal flex justify-between items-center pb-3 border-b border-gray-600 cursor-pointer"
				@click="handleSelect(item.id)"
			>
				<span>{{ item.name }}</span>
				<BaseIcon
					name="base--fill--chevron-right"
					class="text-[18px] flex-shrink-0"
				/>
			</li>
		</ul>
	</div>
</template>

<style scoped>

</style>
